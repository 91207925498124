import React, { Suspense, lazy } from "react"
import { Switch, Route } from "react-router-dom"
import Spinner from "./components/Spinner"

const Error404 = lazy(() => import("./pages/Error404"))
const NewVideopage = lazy(() => import("./pages/NewVideoPage"))
const SetMeUpPage = lazy(() => import("./pages/SetMeUpVideoPage"))

function AppRoutes(props) {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>       
        <Route exact path="/:id" component={NewVideopage} />
        <Route exact path="/videos/:id" component={NewVideopage} />
        <Route exact path="/setmeup/:id" component={SetMeUpPage} />
        <Route component={Error404} />
      </Switch>
    </Suspense>
  )
}

export default AppRoutes
